/* global site */

// Import site modules
// import ('./site/cookie-policy.js');
import ('./site/scroll-to.js');

window.site = (window.site || {});

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = (function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  let inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  const config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {},
  };

  /**
   * Initializes the class.
   * @public
   */
  const init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = (options || {});

    if (options.env) { config.env = options.env; }
    if (options.csrf) { config.csrf = options.csrf; }
    if (options.csrfName) { config.csrfName = options.csrfName; }
    if (options.locale) { config.locale = options.locale; }
    if (options.device) { config.device = options.device; }
    if (options.preview) { config.preview = options.preview; }
    if (options.general) { config.general = options.general; }

    // Global
    // Detect css-grid un-supported
    const el = document.createElement("div")
    const supportsGrid = "string" == typeof el.style.grid;

    if(!supportsGrid) {
      const body = document.getElementsByTagName("body")[0].classList.add('is-ie-11');
      return
    }

    // IE 11 || Old Browser - Ignore zone
    console.table(options);

    // Mobile Menu
    document.querySelector('.mobile-menu-trigger').addEventListener('click', function(e) {
      e.preventDefault();
      document.querySelector('.navigation-mobile').classList.add('is-active');
      document.querySelector('.navigation-mobile-overlay').classList.add('is-active');
    });

    document.querySelector('.navigation-close').addEventListener('click', function(e) {
      e.preventDefault();
      document.querySelector('.navigation-mobile').classList.remove('is-active');
      document.querySelector('.navigation-mobile-overlay').classList.remove('is-active');
    });

    document.querySelector('.navigation-mobile-overlay').addEventListener('click', function(e) {
      e.preventDefault();
      document.querySelector('.navigation-mobile').classList.remove('is-active');
      document.querySelector('.navigation-mobile-overlay').classList.remove('is-active');
    });

    // Register ServiceWorker
    // if ('serviceWorker' in navigator) {
    //   console.log('Registering ServiceWorker...');
    //   window.addEventListener('load', function() {
    //       navigator.serviceWorker.register('/sw.js', {
    //           scope: "/"
    //       }).then(function(registration) {
    //           // Registration was successful
    //           console.log('ServiceWorker registration successful with scope: ', registration.scope);
    //           // Trim the caches on load
    //           navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage({
    //               command: "trimCaches"
    //           });
    //      }).catch(function(err) {
    //           // registration failed :(
    //           console.log('ServiceWorker registration failed: ', err);
    //       });
    //   });
    // }

    // PreRender for fast website
    function prerenderlink(e) {
      // console.log(e, e.currentTarget, e.currentTarget.href);
      var head = document.getElementsByTagName("head")[0];
      var refs = head.childNodes;
      var ref = refs[ refs.length - 1];

      var elements = head.getElementsByTagName("link");
      Array.prototype.forEach.call(elements, function(el, i) {
        if (("rel" in el) && (el.rel === "prerender"))
          el.parentNode.removeChild(el);
      });

      var prerenderTag = document.createElement("link");
      prerenderTag.rel = "prerender";
      prerenderTag.href = e.currentTarget.href;
      ref.parentNode.insertBefore(prerenderTag,  ref);
    }

    const $prerenderLinks = document.querySelectorAll('#page-header a');
    [].slice.call($prerenderLinks).forEach(function(elem) {
      elem.addEventListener('mouseenter', prerenderlink);
    });

    if (document.querySelector('.inline-carousel')) {
      var inlineSlideRight = document.querySelector('[data-inline-slide-right]');
      inlineSlideRight.onclick = function (e) {
        e.preventDefault();
         sideScroll(document.querySelector('.inline-carousel'), 'right', 5, document.querySelector('.inline-carousel-cell').offsetWidth, 10);
      };

      var inlineSlideLeft = document.querySelector('[data-inline-slide-left]');
      inlineSlideLeft.onclick = function (e) {
        e.preventDefault();
         sideScroll(document.querySelector('.inline-carousel'), 'left', 5, document.querySelector('.inline-carousel-cell').offsetWidth, 10);
      };
    }

    if (document.querySelector('.fade-carousel')) {
      var currentSlide = 1;
      var totalSlides = parseInt(document.querySelectorAll('.fade-carousel-cell').length);
      var fadeSlidePrev = document.querySelector('[data-fade-state-prev]');
      var fadeSlideNext = document.querySelector('[data-fade-state-next]');

      fadeSlidePrev.onclick = function (e) {
        e.preventDefault();
        currentSlide--;
        if (currentSlide < 1) {
          currentSlide = totalSlides;
        }
        fadeSlide(currentSlide);
      };

      fadeSlideNext.onclick = function (e) {
        e.preventDefault();
        currentSlide++;
        if (currentSlide > totalSlides) {
          currentSlide = 1;
        }
        fadeSlide(currentSlide);
      };
    }

    function fadeSlide(currentSlide) {
      document.querySelector('.fade-carousel').classList.remove('transition-in');
      document.querySelector('.fade-carousel').classList.add('transition-out');

      setTimeout(function() {
        document.querySelector('.fade-carousel').classList.remove('state1', 'state2', 'state3', 'state4', 'state5', 'state6', 'state7', 'state8', 'state9', 'state10');
        document.querySelector('.fade-carousel').classList.add('state' + currentSlide);

        setTimeout(function() {
          document.querySelector('.fade-carousel').classList.remove('transition-out');
          document.querySelector('.fade-carousel').classList.add('transition-in');
        }, 155);
      }, 855);
    }

    function sideScroll(element, direction, speed, distance, step){
        var scrollAmount = 0;
        var slideTimer = setInterval(function(){
            if(direction == 'left'){
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if(scrollAmount >= distance){
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    setTimeout(function() {

      console.log('Start of Anims();');

      document.querySelector('body').classList.add('dom-loaded', 'is-animated');

      const scroll = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        offset: [0, 0],
        repeat: false,
        smoothMobile: false,
        getDirection: true
      });

      var refresher = null;

      scroll.on('scroll', (instance) => {
        document.documentElement.setAttribute('data-direction', instance.direction);

        if (instance.delta.y >= 50) {
          document.querySelector('body').classList.add('hide-nav');
        } else {
          document.querySelector('body').classList.remove('hide-nav');
        }

        if (instance.delta.y >= 300) {
          document.querySelector('body').classList.add('hide-both-nav');
        } else {
          document.querySelector('body').classList.remove('hide-both-nav');
        }

        clearTimeout(refresher);
        refresher = null;

        refresher = setTimeout(function() {
          scroll.update();
        }, 2855);
      });

      document.querySelector('body').classList.add('is-loaded');

      // Smooth Scroll to href component
      const scrollerDataAttr = 'data-scrollto';
      [].slice.call(document.querySelectorAll('['+scrollerDataAttr+']')).forEach(function(elem) {
        elem.addEventListener('click', (e) => {
          e.preventDefault();
          const target = elem.getAttribute('href');
          scroll.scrollTo(target);
        });
      });

      setTimeout(function() {
        scroll.update();
      }, 755);
    }, 555);

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  const getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init,
    config: getConfig,
  };
}());
